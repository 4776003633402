<template>
	<div>
		<wc-naglowek
			v-if="pokazNaglowek==='true'"
			:pokazNaglowek="pokazNaglowek"
			:pokazSchowek="pokazSchowek"
			:czyPanelKlienta="czyPanelKlienta"
			:czyWitrynaFirmowa="czyWitrynaFirmowa"
		></wc-naglowek>
		<wc-stopka v-if="pokazStopke==='true'" :pokazStopke="pokazStopke" :stopkaZawiera="stopkaZawiera"></wc-stopka>
	</div>
</template>

<script>
	import wcNaglowek from "./navi/naglowek/wc-naglowek.vue"
	import wcStopka from "./navi/stopka/wc-stopka.vue"

	export default {
		name: "Navi",
		components: { wcNaglowek, wcStopka },
		props: {
			pokazNaglowek: {
				type: String
			},
			pokazStopke: {
				type: String
			},
			pokazSchowek: {
				type: String
			},
			czyPanelKlienta: {
				default() {
					return "false";
				},
				type: String
			},
			czyWitrynaFirmowa: {
				default() {
					return "false";
				},
				type: String
			},
			stopkaZawiera: {
				default() {
					return {
						kontakt: true,
						newsletter: false,
						menuDolne: true
					}
				},
				type: Object
			}
		}
	}
</script>

<style lang="scss">
	@import url("https://fonts.googleapis.com/css?family=Lato:400,700&subset=latin-ext");
</style>
