import _ from 'lodash' 
import 'babel-polyfill'

class Akordeon {

    constructor({
        
        elementDocelowy = '.akordeon',
        pokazIkonyUI = false,
        maxWidth = false, //lub number
        minWidth = false, //lub number
        
        css = {},
        offsetTop = 0
    }) {
        this.pobierzSzerokoscOkna();
        this.elementDocelowy = elementDocelowy;
        this.pokazIkonyUI = pokazIkonyUI;
        this.maxWidth = maxWidth;
        this.minWidth = minWidth;
        this.css = _.defaults(css, { 
            arrowWidth : 5,
            width : 10,
            height : 10,
            transitionTime : 200,
            transitionEasing : 'ease',
        });
        this.offsetTop = offsetTop;
    }

    triggerZawieraSubmenu(trigger) {
        
        return trigger.querySelectorAll('ul').length > 0;
    }
        
    zdarzeniaAkordeonu() {
        
        return {
            onResize: () => {},
            onInit: () => {},
            onOpen: () => {},
            onClose: () => {},
            onDestroy: () => {}
        };    
    } 

    pobierzSzerokoscOkna() {
        
        return this.szerokoscOkna = window.innerWidth;
    }

    pobierzPierwotneStyle(el, array) {

        array.push(el.getAttribute("style"));
        return array;
    }

    nadajPierwotneStyle(elems, style) {
        
        elems.map( (el, i) => {
            el.style.cssText = style[i];
        });
    } 

    nieZawieraSieWBreakpointach() {
        
        //jeśli brak argumentów w wywołaniu - wyświetl Akordeon dla każdej szerokości ekranu
        if ( this.minWidth === false && this.maxWidth === false ) {
            
            return false; 
        }
        //jeśli brak argumentu minWidth - sprawdź czy aktualna szerokość okna jest większa niż zadane maxWidth 
        else if ( this.minWidth === false ) {
            
            return this.szerokoscOkna > this.maxWidth;
        }
        //jeśli brak argumentu maxWidth - sprawdź czy aktualna szerokość okna jest mniejsza niż zadane maxWidth 
        else if ( this.maxWidth === false ) {
            
            return this.szerokoscOkna < this.minWidth;
        } 
        //jeśli podane są oba breakpointy - sprawdź czy szerokość okna mieści się w tym zakresie 
        else {
            return (
                this.szerokoscOkna < this.minWidth 
                ||
                this.szerokoscOkna > this.maxWidth 
            );
        }
    }

    dodajPodstawoweStyle() {
        if(this.dodanoPodstawoweStyle === true) {
            return false;
        }
        if(this.nieZawieraSieWBreakpointach()) { 
            
            this.listaTriggerow.map( el => el.style.cssText = this.styl.triggerNieaktywny );
            this.submenus.map( el => {
                this.pobierzPierwotneStyle(el, this.pierwotneStyle);
                //el.style.cssText = this.styl.submenuOtwarte;               
            });
            
            this.onDestroy();
            this.usunPodstawoweStyle();
            this.nadajPierwotneStyle(this.submenus, this.pierwotneStyle);
            return false; 
        }

        if (this.offsetTop > 0) {
            
            const maxH = window.outerHeight - this.offsetTop;
            this.elementDocelowy.style.cssText = `width: 100%; overflow: auto; display: block; max-height: ${maxH}px`;

        } else {
            
            this.elementDocelowy.style.cssText = 'width: 100%; overflow: visible; display: block;';
        }

        this.elementDocelowy.classList.add('akordeon');
        this.listaTriggerow.map( el => { 
            el.style.cssText = this.styl.triggerAktywny;
            if(el.children.length > 1) {
                el.classList.add('akordeon-rodzic');  
            }
        });
        
        this.submenus.map( el => el.style.cssText = this.styl.submenuZamkniete );
        this.linkiSubmenu.map( el => el.style.cssText = this.styl.linkSubmenu);
        this.elementySubmenu.map( el => el.style.cssText = this.styl.elementSubmenu);
        this.dodanoPodstawoweStyle = true;
    }

    usunPodstawoweStyle() {

        if(!this.nieZawieraSieWBreakpointach()) {

            return false;
        }

        const elementyDoWyczyszczenia = [
            
            this.elementDocelowy,
            this.listaTriggerow,
            this.submenus,
            this.linkiSubmenu,
            this.elementySubmenu
        ]
        
        const czyscElementyZagniezdzone = (children) => {
            _.forEach(children, (elem) => {
                if(_.isArray(elem)) {
                    return czyscElementyZagniezdzone(elem)
                }
                elem.removeAttribute('style')
                czyscElementyZagniezdzone(elem.children)
            })
        }
        
        czyscElementyZagniezdzone(elementyDoWyczyszczenia)
        
        this.elementDocelowy.classList.remove('akordeon');
        this.elementDocelowy.removeAttribute('style');

        this.dodanoPodstawoweStyle = false;
    }
    
    dodajIkonyUI() {

        if(this.pokazIkonyUI === false) {
            
            return false;
        }
 
        if(this.nieZawieraSieWBreakpointach() && this.dodanoIkony === true) { 
            
            this.usunIkonyUI();
            return false;
        } 

        if(!this.nieZawieraSieWBreakpointach() && this.dodanoIkony === true) {
            
            return false;
        }
        
        if(this.nieZawieraSieWBreakpointach()) {
            
            return false;
        }

        this.listaTriggerow.map( (el) => {
                
            const ikonaUI = document.createElement('span');
            ikonaUI.classList.add('akordeon__ikona');
            ikonaUI.style.cssText = this.styl.ikonaUiZamknieta;

            if(this.triggerZawieraSubmenu(el)) {
                
                el.appendChild(ikonaUI);
            }
        
            this.dodanoIkony = true;
        });
    }

    usunIkonyUI() {
        
        this.listaTriggerow.map( (el) => {
            
            if(this.triggerZawieraSubmenu(el)) {
                
                el.querySelector('.akordeon__ikona').remove();
            }
        });

        this.dodanoIkony = false;
    }

    nieZawieraKlasy(e, listaKlas) {
        
        let nieZawiera = true;
        listaKlas.map(klasa => {
            
            if (e.target.classList.contains(klasa)) {
                nieZawiera = false;
            }
        });
        return nieZawiera;
    }

    zwinProzostaleElementy(e) {

        const listaKlasRodzicow = ['akordeon-rodzic', 'parent'];

        if (this.nieZawieraSieWBreakpointach() || this.nieZawieraKlasy(e, listaKlasRodzicow) === true ) { 
            return false;
        }

        this.submenus.forEach(el => el.style.cssText = this.styl.submenuZamkniete);
        
        if(this.pokazIkonyUI === false) {
            return false;
        }
        
        this.listaTriggerow.map( (el) => { 
                        
            if(this.triggerZawieraSubmenu(el)) {  

                el.querySelector('.akordeon__ikona').style.cssText = this.styl.ikonaUiZamknieta;  
            }   
        });  
    }
    
    zwinRozwinAkordeon(el) {

        if (this.nieZawieraSieWBreakpointach()) {
            return false
        }
        const wskazaneSubmenu = el.querySelector('ul')

        if (wskazaneSubmenu == null) {
            return false
        }
        
        const submenu = this.submenus.filter(elem => elem === wskazaneSubmenu)[0]
        
        submenu.classList.toggle('open');
        const wysokoscSubmenu = submenu.scrollHeight;
        const submenuJestOtwarte = submenu.classList.contains('open');
        const ikonaUI = el.querySelector('.akordeon__ikona');
        if (submenuJestOtwarte) {
            
            this.elementDocelowy.parentNode.style.cssText = 'overflow: visible';
            
            submenu.style.cssText = 
            `
                height: ${wysokoscSubmenu}px;
                display: block;
                width: 100%;
                position: static; 
                overflow: visible;
                transition: height ${this.css.transitionTime}ms ${this.css.transitionEasing};
            `;

            if(this.pokazIkonyUI === false) {
                return false;
            }

            ikonaUI.style.cssText = this.styl.ikonaUiOtwarta;
        }
        
        else {
            
            submenu.style.cssText = this.styl.submenuZamkniete;

            if(this.pokazIkonyUI === false) {
                return false;
            }
            
            ikonaUI.style.cssText = this.styl.ikonaUiZamknieta;
        }
    }

    init() {
        this.elementDocelowyIstnieje = ([null, undefined, ''].indexOf(this.elementDocelowy) < 0);
        this.pierwotneStyle = [];
        
        setTimeout(()=> {
            if(this.elementDocelowyIstnieje) {
                this.listaTriggerow = [...this.elementDocelowy.children];
                this.submenus = [...this.listaTriggerow].flatMap( el => el.querySelector('ul')).filter( el => el !== null );
                this.elementySubmenu = [...this.submenus].flatMap( el => [...el.children] );
                this.linkiSubmenu = [...this.elementySubmenu].flatMap( el => [...el.children] );
                this.styl = {
                        
                    triggerNieaktywny : 
                    `
                    overflow: visible;
                    `,
                            
                    triggerAktywny :
                    `
                    position: relative;
                    overflow: hidden;
                    display:block;
                    cursor: pointer;
                    width: 100%;
                    margin:0;
                    border-bottom: 1px solid rgba(0,0,0,.2);
                    `,
                    
                    submenuZamkniete : 
                    `
                    position: static;
                    width: 100%;
                    height: 0px;
                    margin: 0px;
                    overflow: hidden;
                    transition: height ${this.css.transitionTime}ms ${this.css.transitionEasing};
                    `,
                    
                    submenuOtwarte : 
                    `   
                    position: static;
                    width: 100%; 
                    height: auto;
                    overflow: visible;
                    transition: height ${this.css.transitionTime}ms ${this.css.transitionEasing};
                    `,
                    
                    elementSubmenu : 
                    `
                    width: 100%;
                    display: block;
                    `,
                    
                    linkSubmenu : 
                    `   
                    width: 100%;
                    display: block;
                    height: auto;
                    `,
                    
                    ikonaUiZamknieta : 
                    `
                    border: solid #fff;
                    border-width: 0 ${this.css.arrowWidth}px ${this.css.arrowWidth}px 0;
                    width: ${this.css.width}px;
                    height: ${this.css.height}px; 
                    padding: 5px; 
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    position: absolute;
                    top: 7px;
                    right: 20px;
                    transition: all .3s ease;
                    pointer-events: none;
                    `,
                    
                    ikonaUiOtwarta : 
                    `
                    border: solid #fff;
                    border-width: 0 ${this.css.arrowWidth}px ${this.css.arrowWidth}px 0;
                    width: ${this.css.width}px;
                    height: ${this.css.height}px; 
                    padding: 5px; 
                    -webkit-transform: rotate(45deg);
                    transform: rotate(225deg);
                    position: absolute;
                    top: 15px;
                    right: 20px;
                    transition: all .3s ease;
                    pointer-events: none;
                    `
                }
                
                _.forEach(this.zdarzeniaAkordeonu(), (val, key)=>{
                    this[key] = val;                
                }); 
            }
            
            if(!this.elementDocelowyIstnieje) { 
                
                console.warn('Musisz podać poprawny element docelowy dla Akordeonu!'); 
                return false;
            }
            
            this.dodajPodstawoweStyle();
            this.dodajIkonyUI();
            
            window.addEventListener('resize', _.debounce(() => { 
                
                this.pobierzSzerokoscOkna();
                this.usunPodstawoweStyle();
                this.dodajPodstawoweStyle();
                this.dodajIkonyUI();
                this.onResize();
                
            }, 200));
            
            this.listaTriggerow.map( el => { 
                el.addEventListener('click', (e) => {
                    if(e.target.href && e.target.href.includes('#')) {
                        e.preventDefault()
                    }
                    this.zwinProzostaleElementy(e);
                    this.zwinRozwinAkordeon(el, e);
                });
            }); 
            
            return this; //aktualna instancja obiektu
        },0)
    }
}

export default Akordeon