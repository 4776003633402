import 'babel-polyfill'
import Akordeon from "./../../../../scripts/akordeon.js"
import breakpoints from './../../../../scripts/breakpoints.js'

class Stopka {
    
    constructor() {
        
        //Konfiguracja
        this.szerokoscKolumny = parseInt('120px')
        
    }
    
    //Wywołania metod
    init(context) {
        this.context = context ? context : document
        setTimeout(()=> {
            this.menu = this.context.querySelector('.stopka__menu');
            this.naglowekMenu = this.context.getElementsByClassName('stopka__naglowek');
            this.akordeon = new Akordeon({
                elementDocelowy : this.menu,
                pokazIkonyUI :  true,
                maxWidth: breakpoints.lg -1,
                css : { 
                    arrowWidth : 3,
                    width: 2,
                    height: 2,
                    transitionTime : 200,
                    transitionEasing : 'ease', 
                }
            });
            this.akordeon.init()
        },0 ) 

    }
}

export default Stopka;