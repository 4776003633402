import 'babel-polyfill'
import 'whatwg-fetch'
import _ from "lodash"
import Cookies from "js-cookie"
import md5 from "js-md5"

const $delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const $cDOM = (elem) => {
    window.$cachedDOM = {}
    if (elem) {
        window.$cachedDOM[elem] = typeof window.$cachedDOM[elem] != "undefined" &&
            window.$cachedDOM[elem].length > 0 ?
            window.$cachedDOM[elem] :
            document.querySelectorAll(elem);
        if (window.$cachedDOM[elem].length == 1) window.$cachedDOM[elem] = window.$cachedDOM[elem][0]
        return window.$cachedDOM[elem];
    } else {
        return
    }
}
class Newsletter {
    constructor(urlApi) {
        this.urlApi = urlApi || "/api/newsletter/dodaj";
        this.messageBody = (email) => {
            return JSON.stringify({
                email: email || '' 
            });
        };
        document.addEventListener('stopkaMounted', (e)=> {
            this.context = document.querySelector('[data-stopka]') ? document : document.querySelector('wc-navi').shadowRoot
            this.stopka = this.context.querySelector('[data-stopka]')
            if(!this.stopka) {
                return false
            }
            this.inputElem = this.stopka.querySelector('.newsletter-v3__input--text');
            this.submitElem = this.stopka.querySelector('.newsletter-v3__input--btn');
        })
    }

    getInputEmail() {
        return this.inputElem.value;
    }

    inputEmailisValid(text) {
        return (text || this.getInputEmail()).match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,4}$/g) != null ? true : false;
    }

    fetchEmail(email) {
        return fetch(this.urlApi, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: this.messageBody(email)
            })
            .then(res => {
                if (!res.ok) return false; // Promise.reject(new Error(`HTTP Error ${res.status}`))
                return res.json();
            })
            .catch(err =>{ 
                console.error(err); 
                return false
            });
    }
    inputState(handleState) {
        if (handleState) {
            this.formStateReadOnly()
            return
        } else if (this.inputEmailisValid() && (!handleState || _.isUndefined(handleState))) {
            this.formStateValid()
            return
        } else {
            this.formStateInvalid()
            return
        }
    }
    formStateValid() {
        this.submitElem.classList.remove("u--gray")
        this.submitElem.removeAttribute('disabled')
        this.submitElem.innerHTML = "ZAPISZ SIĘ"
    }
    formStateInvalid() {
        this.submitElem.classList.add("u--gray");
        this.submitElem.setAttribute('disabled', 'disabled')
        this.submitElem.innerHTML = "Popraw Adres Email"
    }
    formStateReadOnly() {
        this.submitElem.classList.remove("u--gray")
        this.submitElem.classList.remove("u--green")
        this.submitElem.setAttribute('disabled', 'disabled')
        this.submitElem.innerHTML = "ZAPISZ SIĘ"
    }
    formStateEmailSent(){
        this.submitElem.classList.add("u--green")
        this.submitElem.innerHTML = "Zapisano Email"
    }
    
    async sendEmail() {
            let response = await this.fetchEmail(this.getInputEmail());
            this.formStateEmailSent();
            return {"response": response,"email":this.getInputEmail()}    
    }

    emailCookie(opt){
            Cookies.set('emailclient', opt.email, { expires: 365 * 10});            
    }
    newsletterIdCookie(opt){        
            Cookies.set("smclient", opt.response.clientId, { expires: 365 * 10 }); 
    }
    blueRankCookies(opt){    
            Cookies.set("cruid", md5(opt.email), { expires: 365 * 10 });  
    }

    inputStateEventHandler() {
        document.addEventListener('stopkaMounted', ()=> {
            return this.inputElem.addEventListener("change",() => {
                this.inputState()
            })
        })
    }
    inputStatePreventSubmit() {
        document.addEventListener('stopkaMounted', ()=> {
            return  this.inputElem.addEventListener("change",(e) => {
                e.preventDefault()
            })
        })
    }
    sendEmailEventHandler() {
        document.addEventListener('stopkaMounted', ()=> {
            return this.submitElem.addEventListener("click", async (e) => {
                e.preventDefault();
                let responseEmail
                if (this.inputEmailisValid()) {
                    responseEmail = await this.sendEmail()
                    if (! _.isUndefined(responseEmail) && responseEmail ) {
                        this.blueRankCookies(responseEmail)
                        this.newsletterIdCookie(responseEmail)
                        this.emailCookie(responseEmail)
                    }
                    
                    $delay(3000).then(() => { this.redirectToNlSplashScreen() });
                }
            });
        }) 
    }

    redirectToNlSplashScreen() {
        window.location.href = "/newsletter/miasto-wylotu#nowy";
    }

    init() {
        document.addEventListener("DOMContentLoaded", () => {
            this.inputStateEventHandler()
            this.inputStatePreventSubmit();
            this.sendEmailEventHandler()
        });
    }
}

export default Newsletter