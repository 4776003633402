<template>
    <div>
        <nav class="stopka" v-if="menus" data-stopka>
            <div class="stopka__menu-wrapper">
                <div class="stopka__menu-wrap-inner">
                    <ul class="stopka__menu">
                        <li v-for="(menu, index) in menus.Menu" :key="index" class="stopka__naglowek">
                            <span class="stopka__naglowek-nazwa parent">{{ menu.nazwa }}</span>
                            <ul class="stopka__lista">
                                <li v-for="(element, subindex) in menu.elements" :key="subindex" class="stopka__element">
                                    <a :href="formatujUrl(element.url)" class="stopka__link">
                                        {{ element.nazwa }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="stopka__social-wrapper">
                <div class="stopka__social-wrap-inner">
                    <ul class="social__menu">
                        <li v-for="(social, index) in menus.Social" :key="index" class="social__element">
                            <a :href="social.url" class="social__link">
                                <div class="social__ikona-wrap">
                                    <img :src="social.urlObrazka" class="social__ikona" :alt="social.nazwa">
                                </div>
                                <span class="social__nazwa">{{ social.nazwa }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="stopka__copyright">
                <div class="stopka__copyright-inner">
                    <span class="stopka__copyright-text">
                        Wszelkie prawa zastrzeżone &copy;1991-{{biezacyRok}} Biuro podróży Rainbow Tours S.A.
                    </span>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import 'babel-polyfill'
import mixins from '@/scripts/mixins.js'
import menuDolne from './menu-dolne.js'
export default {
    name: 'menuDolne',
    data() {
        return {
            menus: null
        }
    },
    computed: {
        biezacyRok() {
            const date = new Date()
            return date.getFullYear()
        }
    },
    props: {
        pokazStopke: String
    },
    methods: {
        formatujUrl(url) {
            if(url.includes('http')) {
                return url
            } else {
                return `https://r.pl${url}`
            }
        }
    },
    mixins: [mixins],
    async mounted() {
        if(this.pokazStopke == 'false') {
            return false
        }
        await this.getMenu('https://images2.r.pl/r-pl/menu-dolne/stopka.json')
        this.menuDolne = new menuDolne()
        this.menuDolne.init(this.$el)
    }
}
</script>
    
<style lang="scss"> 
    @import "./menu-dolne.scss";
</style>
