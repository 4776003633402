<template>
	<div>
		<a href="//r.pl/schowek" class="naglowek__link">
			<slot></slot>
			<div class="schowek-count" data-schowek></div>
		</a>
	</div>
</template>

<script>
	import Schowek from './schowek.js'

	export default {
		name: 'Schowek',
		data () {
			return {
				schowek: null,
			}
		},
		async mounted() {
			this.schowek = new Schowek(this.$el)
			await this.schowek.getCount()
		}
	}
</script>

<style lang="scss">
	@import "./schowek.scss";
</style>
