<template>
	<div>
		<div class="wc-navi__naglowek-wrapper">
			<nav class="wc-navi__naglowek" data-naglowek ref="naglowek">
				<a href="https://r.pl" title="Powrót do strony głównej Rainbow" class="wc-navi__naglowek__logo">
					<img src="https://r.pl/Content/Svg/symbols/logo-rainbow.svg" alt="Biuro Podróży Rainbow" class="wc-navi__naglowek__logo-img">
				</a>
				<div class="wc-navi__naglowek__menu-wrapper" data-naglowek-menu-wrapper>
					<ul class="wc-navi__naglowek__menu wc-navi__menu" data-naglowek-menu>
						<li v-for="(menu, index) in menus" :key="index"  class="wc-navi__menu__element">
							<a :href="formatujUrl(menu.URL)" :title="menu.Opis" class="wc-navi__menu__link" :class="{ parent: hasParentClass(menu) }">{{menu.Nazwa}}</a>
							<ul v-if="menu.ElementyWRzedzie != 0" :data-liczba_kolumn="menu.ElementyWRzedzie" class="wc-navi__menu__submenu" data-naglowek-submenu>
								<li v-for="(submenu, subindex) in menu.ListaElementow" :key="subindex" class="wc-navi__menu__subelement">
									<a :href="formatujUrl(submenu.URL)" class="wc-navi__menu__sublink" :title="submenu.Opis">
										<img :src="submenu.IconURL" class="wc-navi__menu__ikona">
										<span class="wc-navi__menu__tytul">{{submenu.Nazwa}}</span>
									</a>
								</li>
							</ul>
						</li>
						<li class="wc-navi__menu__element wc-navi__menu__telefon">
							<div class="wc-navi__menu__telefon-inner">
								<div class="wc-navi__menu__telefon-opis">
									<span class="wc-navi__menu__callcenter">Call center</span>
									<span class="wc-navi__menu__telefon-heading">Pracujemy codziennie 8 - 22</span>
								</div>
								<div class="wc-navi__menu__telefon-button">
									<a href="tel:426803851">
										<img src="//r.pl/_Shared/Content/Icons/telefon.svg" />
										<span>Zadzwoń</span>
									</a>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="wc-navi__naglowek__telefon">
					<a :href="formatujUrl('/biura')" class="wc-navi__naglowek__link">
						<phoneIcon class="wc-navi__naglowek__ikona" />
						<span class="wc-navi__naglowek__tytul">42 680 38 51</span>
					</a>
				</div>
				<div class="wc-navi__naglowek__biura">
					<a :href="formatujUrl('/biura')" class="wc-navi__naglowek__link">
						<officeIcon class="wc-navi__naglowek__ikona" />
					</a>
				</div>
				<div v-if="pokazIkoneKoszyka" class="wc-navi__naglowek__koszyk">
					<a href="#" @click="przelaczKoszyk($event)">
						<cartIcon class="wc-navi__naglowek__ikona" />
						<span v-if="koszykCount > 0" class="wc-navi__naglowek__koszyk-counter">{{koszykCount}}</span>
					</a>
				</div>
				<div v-if="pokazIkoneKlienta" class="wc-navi__naglowek__panel-klienta">
					<a href="#" @click="przelaczLogowanie($event)" class="wc-navi__naglowek__link">
						<panelIcon class="wc-navi__naglowek__ikona" width="24" height="24" />
					</a>
				</div>
				<schowek v-if="pokazSchowek=='true'" class="wc-navi__naglowek__schowek">
					<schowekIcon class="wc-navi__naglowek__ikona" width="24" height="24" />
				</schowek>
				<div class="wc-navi__naglowek__hamburger">
					<a href="#" class="wc-navi__naglowek_hamburger-button" data-naglowek-hamburger>
						<img src="//images2.r.pl/r-pl/svg/burger.svg" alt="Pokaż menu mobilne" class="wc-navi__naglowek__ikona" />
					</a>
				</div>
				<div class="wc-navi__naglowek__narzedzia"></div>
			</nav>
		</div>
		<div class="wc-navi__naglowek-spacer"></div>
	</div>
</template>

<script>
	// import 'babel-polyfill'
	import mixins from '@/scripts/mixins.js'
	import Naglowek from './naglowek.js'
	import schowek from './schowek/schowek.vue'
	import phoneIcon from '../../../assets/svgs/phone-icon.svg'
	import cartIcon from '../../../assets/svgs/cart-icon.svg'
	import panelIcon from '../../../assets/svgs/panel-icon.svg'
	import schowekIcon from '../../../assets/svgs/heart-icon.svg'
	import officeIcon from '../../../assets/svgs/office-icon.svg'

	export default {
		mixins: [mixins],
		components: { schowek, phoneIcon, cartIcon, panelIcon, schowekIcon, officeIcon },
		data() {
			return {
				menus: null,
				mainMenu: null,
				pokazKoszyk: false,
				pokazLogowanie: false,
				koszykCount: 0
			}
		},
		computed: {
			pokazIkoneKoszyka() {
				return this.czyPanelKlienta == 'true'
			},
			pokazIkoneKlienta() {
				return this.czyPanelKlienta == 'true'
			},
		},
		props: {
			pokazNaglowek: String,
			pokazSchowek: String,
			czyPanelKlienta: String,
			czyWitrynaFirmowa: String
		},
		methods: {
			hasParentClass(menu) {
				return !!menu.ListaElementow.length
			},
			przelaczKoszyk(event) {
				event.preventDefault()
				this.pokazKoszyk = !this.pokazKoszyk
				const pokazKoszyk = new CustomEvent('pokazKoszyk', { detail: this.pokazKoszyk })
				document.dispatchEvent(pokazKoszyk)
			},
			przelaczLogowanie(event) {
				event.preventDefault()
				this.pokazLogowanie = !this.pokazLogowanie
				const pokazLogowanie = new CustomEvent('pokazLogowanie', { detail: this.pokazLogowanie })
				document.dispatchEvent(pokazLogowanie)
			},
			formatujUrl(url) {
				if(url.includes('http')) {
					return url
				} else {
					return `//r.pl${url}`
				}
			},
			nasluchujKoszyka() {
				document.addEventListener('naliczProdukty', (event) => {
					this.koszykCount = event.detail.source.source.value.produkty.length
				})
			},
			dodajStyleKolorowaniaElementowNadrzednych() {
				const arkuszElement = document.createElement('style')
				let arkuszTresc = '\n'
				this.menus.forEach((element, index) => {
					if (element.Kolor && element.Kolor !== 'transparent') {
						arkuszTresc += `.wc-navi__menu__element:nth-child(${index + 1}) > .wc-navi__menu__link { color: ${element.Kolor} }\n`
					}
				})
				arkuszElement.innerHTML = arkuszTresc
				try {
					this.$refs['naglowek'].insertBefore(arkuszElement, this.$refs['naglowek'].firstChild)
				} catch (e) {
					console.log(e)
				}
			}
		},
		mounted() {
			if(this.pokazNaglowek == 'false') {
				return false
			}
			this.getMenu('https://rpl-api.r.pl/menu/api').then(() => {
				this.naglowek = new Naglowek()
				this.naglowek.init(this.$el)
				this.dodajStyleKolorowaniaElementowNadrzednych()
			})
			this.nasluchujKoszyka()
		}
	}
</script>

<style lang="scss">
	@import "./naglowek.scss";
</style>
