<template>
    <div>
        <div class=" newsletter-v3" id="newsletter">
            <div class="newsletter-v3__container">
                <div class="newsletter-v3__row">
                    <div class="newsletter-v3__description">
                        <span>Jeśli chcesz jako pierwszy otrzymać najlepsze oferty wprost na Twoją skrzynkę,</span>
                        <strong>ZAPISZ SIĘ NA NEWSLETTER:</strong>
                    </div>
                    <form class="newsletter-v3__form">
                        <input class="newsletter-v3__input newsletter-v3__input--text" type="email" placeholder="Podaj adres e-mail"/>
                        <button class="newsletter-v3__input newsletter-v3__input--btn" type="submit">ZAPISZ SIĘ</button>
                    </form>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import 'babel-polyfill'
import newsletter from './newsletter.js' 
export default {
    name: 'newsletter',
    mounted() {
        this.newsletter = new newsletter()
        this.newsletter.init()
    }
}
</script>
    
<style lang="scss">
    @import "./newsletter.scss"; 
</style>
