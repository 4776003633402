<template>
	<div id="app">
		<navi
			pokaz-naglowek="true"
			pokaz-stopke="true"
			pokaz-schowek="true"
			czy-panel-klienta="true"
			czy-witryna-firmowa="false"
		></navi>
	</div>
</template>

<script>
	// import 'babel-polyfill'
	import navi from './components/navi.vue'

	export default {
		name: 'App',
		components: {
			navi,
		}
	}
</script>
