import 'babel-polyfill'
import _ from 'lodash' 
import Akordeon from "../../../scripts/akordeon.js";
import Overlay from "../../../scripts/overlay.js";
import breakpoints from '../../../scripts/breakpoints.js'

class Naglowek {
    
    constructor() {
        
        //Konfguracja
        this.szerokoscKolumny = parseInt('121px');
        this.tabletBreakpoint = breakpoints.lg - 1;

        //Szerokość viewportu
        this.szerokoscOkna = window.innerWidth;
        
        //Caching elementów DOM

        _.forEach(this.zdarzeniaNaglowka(), (val, key)=>{
            this[key] = val;                
        }); 
    }

    zdarzeniaNaglowka() {
        
        return {
            onResize: () => {},
            onInit: () => {},
            onOpen: () => {},
            onClose: () => {},
            onDestroy: () => {}
        };    
    } 

    //pobierz liczbe kolumn na podstawie data-layer submenu
    pobierzLiczbeKolumn (el) {
        
        return parseInt(el.dataset.liczba_kolumn);
    }

    //ustaw szerokość Submenu na podstawie konfiguracji
    ustawSzerokoscSubmenu() {
        
        if(this.szerokoscOkna >= this.tabletBreakpoint) {

            // [...this.submenu].map( el => {
            [].slice.call(this.submenu).map( el => {
                
                el.style.cssText = `width: ${(this.pobierzLiczbeKolumn(el) * this.szerokoscKolumny)}px`;
            });
        }
    }

    //Nadaj klasę 'active' dla rodzica po najechaniu na submenu
    aktywujRodzicaSubmenu() {

        function przelaczKlaseActive() {
            this.parentNode.querySelector('a').classList.toggle('active');
        }
        
        //[...this.submenu].map( el => {
        [].slice.call(this.submenu).map( el => {

            el.addEventListener('mouseenter', przelaczKlaseActive);
            el.addEventListener('mouseleave', przelaczKlaseActive);    
        });          
    }
    
    //Usuń prawe i dolne bordery dla właściwych elementów submenu
    usunBorderySubmenu (submenu) {

        const elementySubmenu = submenu.querySelectorAll('li');
        const linkiSubmenu = submenu.querySelectorAll('a');
        const liczbaElementow = elementySubmenu.length; 
        const liczbaKolumn = this.pobierzLiczbeKolumn(submenu);
        
        const elementyOstatniegoRzedu = liczbaKolumn - (liczbaKolumn - liczbaElementow % liczbaKolumn) || liczbaKolumn;

        function usunPrawyBorder(liczbaKolumn, liczbaElementow, linkiSubmenu) {
            
            //[...linkiSubmenu].forEach((el, i) => {
                [].slice.call(linkiSubmenu).forEach((el, i) => {

                if((i + 1) % liczbaKolumn == 0) {
                    //jeśli nie masz pojęcia ocb użyj: console.log(liczbaKolumn, i + 1), powodzenia!
                    el.classList.add('no-border-right'); 
                }
            });
        }
        
        function usunDolnyBorder(elementyOstatniegoRzedu, liczbaElementow, elementySubmenu) {

            //[...elementySubmenu].forEach((el, i) => {
                [].slice.call(elementySubmenu).forEach((el, i) => {
                
                if(i >= liczbaElementow - elementyOstatniegoRzedu) {

                    el.classList.add('no-border-bottom');
                }
            });
        }

        usunPrawyBorder(liczbaKolumn, liczbaElementow, linkiSubmenu);
        usunDolnyBorder(elementyOstatniegoRzedu, liczbaElementow, elementySubmenu);
    }

    //pokaż menu mobilne po kliknieciu w przycisk hamburger
    pokazMenuMobilne() {
                
        function przelaczMenu(event) {
            event.preventDefault();
            const wysokoscWrappera = this.wrapper.scrollHeight;
            this.wrapper.classList.toggle('open');

            const wrapperJestWidoczny = this.wrapper.classList.contains('open'); 

            if (wrapperJestWidoczny) {
                this.wrapper.style.cssText = `height: ${wysokoscWrappera}px`;
                //document.getElementsByTagName('body')[0].classList.add('no-scroll')
            } 
            
            else {
                this.wrapper.style.cssText = `height: 0px`;
                this.wrapper.classList.remove('visible');
                //document.getElementsByTagName('body')[0].classList.remove('no-scroll')
            }
            
            //zwiń submenu 
            // [...this.submenu].map( el => el.classList.remove('visible'));
                
            //[...this.parent].map( el => el.classList.remove('open'));
            [].slice.call(this.parent).map( el => el.classList.remove('open'));
        }

        this.hamburgerButton.addEventListener('click', (event) => {
            przelaczMenu.call(this, event);
            if(this.overlay.wlaczone == undefined) {
                this.overlay.stworzOverlay()
            }
            this.overlay.przelaczOverlay();
        });
    }

    //Wywołania metod
    init(context) {
        this.context = context ? context : document
        setTimeout(()=> {
            this.naglowek = this.context.querySelector('[data-naglowek]');
            this.wrapper = this.naglowek.querySelector('[data-naglowek-menu-wrapper]');
            this.menu = this.naglowek.querySelector('[data-naglowek-menu]');
            this.overlayElement = document.querySelector('.overlay');
            this.hamburgerButton = this.naglowek.querySelector('[data-naglowek-hamburger]');
            
            this.submenu = this.menu.querySelectorAll('[data-naglowek-submenu]');
            this.parent = this.menu.getElementsByClassName('parent');
            this.submenu = Array.prototype.slice.call(this.submenu);
            this.submenu.forEach((el) => this.usunBorderySubmenu(el));
            this.overlay = new Overlay()
            this.akordeon = new Akordeon({
                elementDocelowy : this.menu,
                pokazIkonyUI : true,
                maxWidth: breakpoints.lg - 1,
                css : {
                    arrowWidth : 3,
                    width: 2,
                    height: 2,
                    transitionTime : 200,
                    transitionEasing : 'ease', 
                },
                offsetTop: this.naglowek.offsetHeight
            });

            this.akordeon.init()
            
            document.addEventListener('overlayClosed', ()=> {
                this.wrapper.style.cssText = `height: 0px`;
                this.wrapper.classList.remove('open');
            })
            
            this.ustawSzerokoscSubmenu();
            this.aktywujRodzicaSubmenu();
            this.pokazMenuMobilne();
            
            window.addEventListener('resize', _.debounce(() => {
                
                if(this.akordeon.nieZawieraSieWBreakpointach() && this.wrapper.classList.contains('open')) {
                    
                    this.wrapper.classList.remove('open');
                    this.wrapper.style.cssText = 'height: 0';
                    this.overlay.wylaczOverlay();
                    this.ustawSzerokoscSubmenu();
                } 
                
                this.onResize();
                
            }, 200)); 
        },0 ) 
    } 

}

export default Naglowek