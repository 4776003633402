import 'babel-polyfill'
const breakpoints = {
	"xs" : 0,
	"sm" : 576,
	"md" : 768,
	"lg" : 992,
	"xl" : 1200,
	"fullhd" : 1920 
}   

export default breakpoints