import 'babel-polyfill'
import 'whatwg-fetch'
export default {
    methods: {
        getMenu : async function (url, headerParams) {
            try {
                const menu = await fetch(url, {
                    headers: headerParams,
                })
                const menuData = await menu.json()
                this.menus = menuData 
            }
            catch(error) {
                console.error(error)
            }
        }
    }
}