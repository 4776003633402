// import 'babel-polyfill'
import 'whatwg-fetch'
import Cookies from 'js-cookie'

class Schowek {
	constructor ($parent) {
		this.$parent = $parent || document
		this.$schowek = this.$parent.querySelector('[data-schowek]')
		this.count = 0

		document.addEventListener('aktualizujSchowekIlosc', this.setCountEvent.bind(this))
	}

	async getCount () {
		let count = 0
		const fetchURL = new URL('https://rpl-api.r.pl/v3/schowek/rozmiar')
		if (_.isEmpty(Cookies.get('KlientIdSchowek'))) {
			let s = [],
			itoh = '0123456789ABCDEF',
			i,
			resault
			// Make array of random hex digits. The UUID only has 32 digits in it, but we
			// allocate an extra items to make room for the '-'s we'll be inserting.
			for (i = 0; i < 36; i++) s[i] = Math.floor(Math.random() * 0x10)
			// Conform to RFC-4122, section 4.4
			s[14] = 4 // Set 4 high bits of time_high field to version
			s[19] = (s[19] & 0x3) | 0x8 // Specify 2 high bits of clock sequence
			// Convert to hex chars
			for (i = 0; i < 36; i++) s[i] = itoh[s[i]]
			// Insert '-'s
			s[8] = s[13] = s[18] = s[23] = '-'
			resault = s.join('') // wynikowy UUID

			Cookies.set('KlientIdSchowek', resault, {
				expires: 365
			})
		}
		const klientId = Cookies.get('KlientIdSchowek')
		fetchURL.searchParams.set('KlientId', klientId)

		if (fetchURL) {
			try {
				const data = await fetch(fetchURL.toString(),{
					method: 'GET',
				})
				const schowekIlosc = await data.json()
				count = schowekIlosc
			}
			catch(err) {
				console.error(err)
			}
		}
		this.setCount(count)
	}

	setCountEvent (event) {
		this.setCount(event.detail.ilosc)
	}

	setCount (count) {
		this.$schowek.innerHTML = count
		this.count = parseInt(count, 10)
		this.setVisibility()
	}

	setVisibility () {
		this.$schowek.style.display = this.count > 0 ? 'block' : 'none'
	}
}

export default Schowek
