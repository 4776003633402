<template>
	<div data-stopka id="stopka" class="stopka-wrap">
		<kontakt v-if="stopkaZawiera.kontakt" />
		<newsletter v-if="stopkaZawiera.newsletter" />
		<menuDolne v-if="stopkaZawiera.menuDolne" :pokazStopke="pokazStopke" />
	</div>
</template>

<script>
import "babel-polyfill";
import kontakt from "./kontakt/kontakt.vue";
import newsletter from "./newsletter/newsletter.vue";
import menuDolne from "./menu-dolne/menu-dolne.vue";
export default {
	components: { kontakt, newsletter, menuDolne },
	props: {
		stopkaZawiera: Object,
		pokazStopke: String
	}
};
</script>

<style>
.stopka-wrap {
	margin-top: 30px;
}
</style>

