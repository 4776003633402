import 'babel-polyfill'  
import Vue from 'vue'
import App from './App.vue'
import 'core-js/fn/array/flat-map'
import vueCustomElement from 'vue-custom-element'
import './scripts/document-register-element'
//import styles from './wc-navi.css'

Vue.config.productionTip = false
Vue.use(vueCustomElement)

import navi from './components/navi'
Vue.customElement('wc-navi', navi);

new Vue({
  render: h => h(App),
}).$mount('#app')
