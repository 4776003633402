<template>
    <div>
        <div class="kontakt__wrap">
            <div class="kontakt" data-kontakt>
                <div class="kontakt__item-wrap">
                    <div class="kontakt__item">
                        <div class="kontakt__circle kontakt__circle--phone">
                            <callIcon />
                        </div>
                        <div class="kontakt__content">
                            <div class="kontakt__header">Call center</div>
                            <div class="kontakt__description">
                                <a class="kontakt__phone kontakt__text kontakt__text--nobreak" href="tel:+48426803851">42 680 38 51</a>
                                <span class="kontakt__text">codziennie w godz. <div class="kontakt__text--nobreak">8:00-22:00</div> </span>
                            </div>
                        </div>
                        <a href="tel:426803851" class="kontakt__button" id="kontakt__zadzwon">Zadzwoń</a>
                    </div>
                </div>
                <div class="kontakt__item-wrap">
                    <div class="kontakt__item">
                        <div class="kontakt__circle kontakt__circle--mail">
                            <mailIcon />
                        </div>
                        <div class="kontakt__content">
                            <div class="kontakt__header">Kontakt</div>

                            <div class="kontakt__description kontakt__text">
                                <span>Nasi konsultanci są do Państwa dyspozycji 7 dni w tygodniu</span>
                                <strong>
                                    <a class="kontakt__mail kontakt__text" href="mailto:callcenter@r.pl">callcenter@r.pl</a>
                                </strong>
                            </div>
                        </div>
                        <a href="https://r.pl/kontakt" class="kontakt__button" target="_self">Napisz do nas</a>
                    </div>
                </div>
                <div class="kontakt__item-wrap">
                    <div class="kontakt__item">
                        <div class="kontakt__circle kontakt__circle--rpl">
                            <rIcon />
                        </div>
                        <div class="kontakt__content">
                            <div class="kontakt__header">Biura Rainbow</div>

                            <div class="kontakt__description kontakt__text">
								Odwiedź jedno z ponad 100 biur Rainbow w Polsce i zarezerwuj swój wymarzony urlop
							</div>
                        </div>
                        <a href="https://r.pl/biura" class="kontakt__button" target="_self">Odwiedź nasz salon</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'babel-polyfill'
import callIcon from '../../../../assets/svgs/call-icon.svg'
import mailIcon from '../../../../assets/svgs/mail-icon.svg'
import rIcon from '../../../../assets/svgs/r-icon.svg'
export default {
    name: 'kontakt',
    components: {callIcon, mailIcon, rIcon}
}
</script>
    
<style lang="scss">
    @import "./kontakt.scss"; 
</style>
