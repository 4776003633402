import 'babel-polyfill'
class Overlay {

    constructor() {

        this.body = document.body
        this.overlayCss = {
            inactive: 
                `
                width: 100%;
                height: 0;
                position: absolute;
                z-index: 3;
                top: 0;
                transition: all 300ms ease;
                opacity: 0;
                `,
            active: 
                `
                height: 100%;
                position: fixed;
                background: rgba(0, 0, 0, 0.8);
                opacity: 1;
                width: 100%;
                top: 0;
                z-index: 3;
                `
        }
    }

    stworzOverlay() {
        this.overlay = document.createElement('div');
        this.wlaczone = true;
        this.overlay.setAttribute('class', 'overlay');
        this.body.appendChild(this.overlay);
        this.body.style.cssText = 'overflow: hidden';
        this.overlay.style.cssText = this.overlayCss.inactive
        this.overlay.addEventListener('click', ()=> {
            this.przelaczOverlay()
            }
        )
    }

    wlaczOverlay() {
        this.overlay.classList.add('visible');
        this.body.style.cssText = 'overflow: hidden';
        this.overlay.style.cssText = this.overlayCss.active
    }

    wylaczOverlay() {
        this.overlay.classList.remove('visible');
        this.body.style.cssText = 'overflow: visible';
        this.overlay.style.cssText = this.overlayCss.inactive
    }

    przelaczOverlay() {
        if(this.overlay.classList.contains('visible')) {
            this.wylaczOverlay();
            const overlayClosed = new CustomEvent('overlayClosed', {details: true})
            document.dispatchEvent(overlayClosed)
        } 
        else {
            this.wlaczOverlay();
        }
    }

}

export default Overlay;